table {
    /*display: table;*/
    /*border: dashed orange 1px;*/
    /*transform: scale(1)*/

}

thead {
    /*display: inline-block;*/
    /*position: relative;*/
    /*border: dashed orange 1px;*/
    /*transform: scale(1);*/
}

tbody {
    /*display: inline-block;*/
    /*position: relative;*/
    /*border: dashed orange 1px;*/
    /*transform: scale(1);*/
}

/*tr {*/
/*    position: relative;*/
/*    display: table-row;*/
/*    border: dashed orange 1px;*/
/*    width: 100%*/
/*}*/

/*th { display: table-cell }*/

/*td {*/
/*    border: dashed orange 5px !important;*/
/*    display: table-cell;*/
/*}*/


/* Remove table borders and shadows */
.ui.table {
    border: none;
    box-shadow: none;
    background: white;
}

/* Remove table cell borders */
.ui.table td,
.ui.table th {
    border: none;
}

/* Remove the line beneath the header row */
.ui.table thead th {
    border-bottom: none; background: #343634; color: #fff;
    padding: 0.92857143em 0.78571429em 0 0.78571429em;
}

.ui.table thead tr:first-child>th:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ui.table thead tr:last-child>th:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ui.table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ui.table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Change the background color for the first stripe */
.ui.table.striped tbody tr:nth-child(2n + 1) {
    background-color: #ffffff; /* Replace #yourColor1 with your desired color */
}

.ui.table.striped tbody tr:nth-child(2n) {
    background-color: #f2f1f1; /* Replace #yourColor with your desired color */
}

.header-content {
    position: relative;
    z-index: 3;
}

.rating-component .selected {
    background-color: transparent !important;
}
.rating-component .selected::before,
.rating-component .selected::after {
    background-color: transparent !important;
}

.icon-and-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icon-container {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    margin-top: 6px;
}

.ui.labeled.icon.button>.icon, .ui.labeled.icon.buttons>.button>.icon {
    background-color: #4d4e4d;
}

.ui.black.button, .ui.black.buttons .button {
    background-color: #343634;
}

.ui.pagination.menu .active.item {
    background-color: rgba(52,54,52,1);
    color: rgba(255,255,255,1);
}

.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
    opacity: 1;
}

/*.ui.dropdown {*/
/*    background-color: #f5f5f5; !* light grey background *!*/
/*    color: white; !* white text *!*/
/*}*/

/*.ui.dropdown .menu>.item {*/
/*    color: black; !* black dropdown items *!*/
/*}*/

.ui.dropdown .menu>.item {
    background-color: #343634; /* green background on hover */
    color: white; /* white text on hover */
    margin: 3px 4px;
    border-radius: 6px;
    font-size: 12px;
    padding: 9px 8px !important;
}

.ui.dropdown .menu>.item:hover {
    background-color: #3bac57; /* green background on hover */
    color: white; /* white text on hover */
}

.ui.pointing.dropdown>.menu:after {
    background: transparent;
    visibility: hidden;
}

.ui.top.right.pointing.dropdown>.menu {
    background: #636463;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.ui.dropdown .menu .selected.item, .ui.dropdown.selected {
    background-color: #343634;
    color: white;
}

.ui.dropdown .menu .selected.item:hover {
    background-color: #3bac57; /* green background on hover */
    color: white; /* white text on hover */
}

.ui.dropdown.selected:hover {
    background-color: #3bac57; /* green background on hover */
    color: white; /* white text on hover */
}

.ui.table tbody tr.collapsed:nth-of-type(even) {
    background-color: blue; /* set the striped color for even rows */
}

.ui.modal {
    border: solid 5px #3c3c3b !important;
    border-radius: 9px;
    background-color: #f2f1f1;
}

.ui.modal>.header {
    margin: 3px !important;
    background: #b4b5b4 !important;
    border-radius: 5px !important;
}

.ui.modal>.content {
    background: #f2f1f1;
}

/*

#343634
#4d4e4d
*/






