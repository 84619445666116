.ui.page.dimmer {
    background: transparent;
}

.ui.modal {
    box-shadow: none;
    border: solid 1px rgb(118, 122, 119);
}

i.icon.open-new-window:after {
    content: "\f35d";
}
