.ui.secondary.menu .item {
  align-self: flex-end !important;
  font-weight: 300;
  margin: 0 5px 5px 5px !important;
  padding: 16px!important;
  font-size: 2em;
}

.ui.secondary.menu .active.item {
  box-shadow: none;
  background: #1d70b8 !important;
  color: rgba(255,255,255,.95);
  /*border-bottom: solid 2px #282c34;*/
  border-radius: 5px;
}

.ui.secondary.menu .dropdown.item:hover, .ui.secondary.menu .link.item:hover, .ui.secondary.menu a.item:hover {
  background: rgba(41, 106, 166, 0.7);
  color: white;
}

.ui.secondary.menu .active.item:hover {
  color: white;
}

/*.ui.secondary.menu .item:hover {*/
/*  box-shadow: none;*/
/*  background: none !important;*/
/*  border-bottom: solid 2px #888c94;*/
/*  border-radius: unset;*/
/*}*/

.ui.menu .ui.dropdown .menu>.item {
  background-color: #343634 !important;
  color: white !important;
  font-size: 0.6em !important;
}

.ui.menu .ui.dropdown .menu>.item:hover {
  background-color: #3bac57 !important;
  color: white !important;
}

.ui.top.right.pointing.dropdown>.menu {
  background: #636463;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.ui.dropdown .menu>.item {
  background-color: #343634;
  color: white;
  margin: 3px 4px !important;
  border-radius: 6px;
  font-size: 12px;
  padding: 9px 8px !important;
}

.ui.dropdown .menu>.item:hover {
  background-color: #3bac57;
  color: white;
}

.ui.pointing.dropdown>.menu:after {
  background: transparent;
  visibility: hidden;
}

.ui.top.right.pointing.dropdown>.menu {
  background: #636463;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.ui.dropdown .menu .selected.item, .ui.dropdown.selected {
  background-color: #343634;
  color: white;
}

.ui.dropdown .menu .selected.item:hover {
  background-color: #3bac57; /* green background on hover */
  color: white; /* white text on hover */
}

.ui.dropdown.selected:hover {
  background-color: #3bac57; /* green background on hover */
  color: white; /* white text on hover */
}
