.ui.secondary.menu .active.item {
    box-shadow: none;
    background: #1d70b8 !important;
    color: rgba(255,255,255,.95);
    /*border-bottom: solid 2px #282c34;*/
    border-radius: 5px;
}

.ui.secondary.pointing.menu .active.item {
    border: none;
    font-weight: 700;
    color: white;
}

.ui.secondary.pointing.menu .active.item:hover {
    color: white !important;
}

.ui.secondary.menu .dropdown.item:hover, .ui.secondary.menu .link.item:hover, .ui.secondary.menu a.item:hover {
    background: rgba(41, 106, 166, 0.7);
    color: white;
    border-radius: 5px;
}

.ui.secondary.pointing.menu a.item:hover {
    background: rgba(41, 106, 166, 0.7);
    color: white;
}

.ui.secondary.menu .active.item:hover {
    color: white;
}
